@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "Proxima Nova", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  line-height: 106%;
  color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.work-sans-100 {
  font-family: "Work Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 100;
  font-style: normal;
}

.work-sans-200 {
  font-family: "Work Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 200;
  font-style: normal;
}

.work-sans-300 {
  font-family: "Work Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: normal;
}

.work-sans-400 {
  font-family: "Work Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

.work-sans-500 {
  font-family: "Work Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
}

.work-sans-600 {
  font-family: "Work Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
}

.work-sans-700 {
  font-family: "Work Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
}

.work-sans-800 {
  font-family: "Work Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 800;
  font-style: normal;
}

.work-sans-900 {
  font-family: "Work Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 900;
  font-style: normal;
}

/* Headings and text sizes */
h1 {
  font-family: 'ABC Arizona Mix"';
  font-size: 2.25rem; /* 36px */
  font-weight: 700;
  margin-bottom: 0.8rem;
  
}

h2 {
  font-family: 'Inter';
  font-size: 1.8rem; /* 28px */
  font-weight: 600;
  margin-bottom: 0.75rem;
}

h3 {
  font-family: 'Inter';
  font-size: 1.5rem; /* 24px */
  font-weight: 500;
  margin-bottom: 0.7rem;
}

h4 {
  font-family: 'Inter';
  font-size: 1.25rem; /* 20px */
  font-weight: 400;
  line-height: 106%;
}

h5 {
  font-family: 'Inter';
  font-size: 1.1rem; /* 17.6px */
  font-weight: 400;
  margin-bottom: 0.6rem;
}

h6 {
  font-family: 'Inter';
  font-size: 1rem; /* 16px */
  font-weight: 400;
  margin-bottom: 0.5rem;
}

p {
  font-size: 1rem; /* 16px */
  margin-bottom: 1rem;
}

small {
  font-size: 0.875rem; /* 14px */
}

/* Larger and smaller text */
.text-large {
  font-size: 1.25rem; /* 20px */
}

.text-small {
  font-size: 0.875rem; /* 14px */
}

.inter-100 {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: 100;
  font-style: normal;
  font-variation-settings: "slnt" 0;
}

.inter-200 {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: 200;
  font-style: normal;
  font-variation-settings: "slnt" 0;
}

.inter-300 {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: normal;
  font-variation-settings: "slnt" 0;
}

.inter-400 {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-variation-settings: "slnt" 0;
}

.inter-500 {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
  font-variation-settings: "slnt" 0;
}

.inter-600 {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
  font-variation-settings: "slnt" 0;
}

.inter-700 {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
  font-variation-settings: "slnt" 0;
}

.inter-800 {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: 800;
  font-style: normal;
  font-variation-settings: "slnt" 0;
}

.inter-900 {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: 900;
  font-style: normal;
  font-variation-settings: "slnt" 0;
}

.proxima300 {
  font-family: "proxima_novaregular";
  font-style: "normal";

  font-weight:"300";
  line-height:"130%";
}
.proxima400 {
  font-family: "proxima_novaregular";
  font-style: normal;

  font-weight: 400;
  line-height: 130%;
}

.proxima600 {
  font-family: "proxima_novaregular";
  font-style: normal;

  font-weight: 600;
  line-height: 130%;
}

.proxima700 {
  font-family: "proxima_novaregular";
  font-style: normal;

  font-weight: 700;
  line-height: 130%;
}

.arizona {
  font-family: "abc_arizona_mixlight";
  font-style: normal;
}


/* Map */
#map {
  width: 100vw;
  height: 400px;

}

@font-face {
  font-family: 'abc_arizona_mixlight';
  src: url('./fonts/abcarizonamix-light-webfont.woff2') format('woff2'),
       url('./fonts/abcarizonamix-light-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}


@font-face {
  font-family: 'proxima_novaregular';
  src: url('./fonts/proxima-nova-font-webfont.woff2') format('woff2'),
       url('./fonts/proxima-nova-font-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

